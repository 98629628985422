import React, { Component } from "react";
import {
  PdfLoader,
  PdfHighlighter,
  Highlight,
  Popup,
  AreaHighlight,
} from "react-pdf-highlighter";

import Spinner from "../../components/Spinner";
import Sidebar from "../../components/Sidebar";
import {get_client_bylaws, get_property_corpus, get_property_pdf } from "../../api/services";
import encryptService from '../../api/encrypt';
import { insertUrlParam } from "../../utilities/helpers";

const getNextId = () => String(Math.random()).slice(2);

const parseIdFromHash = () =>
  document.location.hash.slice("#highlight-".length);

const resetHash = () => {
  document.location.hash = "";
};

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

const HighlightPopup = ({ comment }) =>
  comment.text ? (
    <div className="Highlight__popup">
      {comment.emoji} {comment.text}
    </div>
  ) : null;

const initialUrl = "";

class ClientScreen extends Component {
  state = {
    property_id: null,
    bylaw_authed: false,
    url: initialUrl,
    selected_corpuses: [],
    bylaw_categories: [],
    client_bylaws: [],
    corpus_sentance: [],
    selected_category: "select",
    drop: [],
    corpus_summary: "",
    keywords: [],
    is_sidebar_loading: true
  };

  componentDidMount(){
    const all =  new URLSearchParams(window.location.search);
    let property_id = null;
    let pid = all.get("pid");
    const category = all.get("category");
    this.setState({selected_category: category});
    if(pid)property_id = encryptService.decryptWithAES(pid);
    if (property_id){
      this.get_pdf_url(property_id)
      this.get_bylaws(property_id);
      this.setState({property_id: property_id});
      window.addEventListener(
        "hashchange",
        this.scrollToHighlightFromHash,
        false
      );
    }
}

  async get_pdf_url(property_id){
    try{
      const get_property_pdf_data = await get_property_pdf(property_id);
      this.setState({ url: get_property_pdf_data });
    }catch(err){console.error(err);}
  }

  async get_bylaws(property_id){
    try{
      const client_bylaws_data = await get_client_bylaws(property_id);
      this.setState({ client_bylaws: client_bylaws_data });
      const categories = [... new Set(client_bylaws_data.map(bylaw => bylaw.category))]; // Only unique categories;
      categories.sort()
      this.setState({bylaw_categories: categories});
      this.setState({is_sidebar_loading: false})
    }catch(err){console.error(err);}
  }
  
  async get_selected_corpuses(category){
    try{
      this.setState({is_sidebar_loading: true})
      const get_property_corpus_data = await get_property_corpus(category, this.state.property_id);
      const selected_corpuses = [];
      get_property_corpus_data.map((corpus) => {
        var item = JSON.parse(corpus.location)
        selected_corpuses.push({
          content: item.content,
          position: {
            boundingRect: {
              x1: item.position.boundingRect.x1,
              y1: item.position.boundingRect.y1,
              x2: item.position.boundingRect.x2,
              y2: item.position.boundingRect.y2,
              width: item.position.boundingRect.width,
              height: item.position.boundingRect.height
            },
            rects: [
              {
                x1: item.position.rects[0].x1,
                y1: item.position.rects[0].y1,
                x2: item.position.rects[0].x2,
                y2: item.position.rects[0].y2,
                width: item.position.rects[0].width,
                height: item.position.rects[0].height
              }
            ],
            pageNumber:  item.position.pageNumber
          },
          comment: item.comment,
          id: item.id.toString(),
          text_id: corpus.id,
          selected: corpus.selected,
          category: corpus.category,
          property_id : this.state.property_id,
        });
        
      });
      this.setState({selected_corpuses: selected_corpuses.filter(corpus_el => corpus_el.selected) });
      this.setState({is_sidebar_loading: false})
    }catch(err){
      console.error(err);
    }
  }

  componentDidUpdate(oldParams, oldState){
    const current_category = this.state.selected_category;
    if (oldState.selected_category !== current_category){
      insertUrlParam('category', current_category);
      this.get_selected_corpuses(current_category);
    }
  }

  resetHighlights = () => {
    this.setState({
      selected_corpuses: [],
    });
  };

  scrollViewerTo = (highlight) => {};

  scrollToHighlightFromHash = () => {
    const highlight = this.getHighlightById(parseIdFromHash());
    if (highlight) {
      this.scrollViewerTo(highlight);
    }
  };

  async change_category(v) {
    resetHash();
  }


  getHighlightById(id) {
    const { selected_corpuses } = this.state;
    return selected_corpuses.find((highlight) => {
      const ok = highlight.id === id;
      return ok;
    });
  }

  handleChangeCategory(e){
    const new_category = e.target.value;
    this.setState({selected_category: new_category})
  }

  render() {
    const { url, selected_corpuses } = this.state;
    return (
      <div className="Pdf">
        <div className="container-fluid">
          <div className="row p-4">
            <div
              className="col-md-8"
              style={{
                height: "92vh",
                width: "70vh",
                overflowY: "scroll",
                overflowX: "hidden",
                position: "relative",
              }}
            >
              <PdfLoader url={url} beforeLoad={<Spinner mainLoader={true} />} >
                {(pdfDocument) => 
                {
                  return (
                  <PdfHighlighter
                    pdfDocument={pdfDocument}
                    enableAreaSelection={(event) => event.shift}
                    onScrollChange={()=>{
                      resetHash();
                    }}
                    scrollRef={(scrollTo) => {
                      this.scrollViewerTo = scrollTo;
                      this.scrollToHighlightFromHash();
                    }}
                    highlightTransform={(
                      highlight,
                      index,
                      setTip,
                      hideTip,
                      viewportToScaled,
                      screenshot,
                      isScrolledTo
                    ) => {
                      const isTextHighlight = !Boolean(
                        highlight.content && highlight.content.image
                      );
                      const component = isTextHighlight ? (
                        <Highlight
                          isScrolledTo={isScrolledTo}
                          position={highlight.position}
                          comment={highlight.comment}
                        />
                      ) : (
                        <AreaHighlight
                          highlight={highlight}
                          onChange={(boundingRect) => {
                            this.updateHighlight(
                              highlight.id,
                              {
                                boundingRect: viewportToScaled(boundingRect),
                              },
                              { image: screenshot(boundingRect) }
                            );
                          }}
                        />
                      );
                      return (
                        <Popup
                          popupContent={<HighlightPopup {...highlight} />}
                          onMouseOver={(popupContent) =>
                            setTip(highlight, (highlight) => popupContent)
                          }
                          onMouseOut={hideTip}
                          key={index}
                          children={component}
                        />
                      );
                    }}
                    highlights={selected_corpuses}
                  />
                )}}
              </PdfLoader>
            </div>
            <div
              className="col-md-4 sidebar-menu"
              style={{
                height: "97vh",
                width: "100vh",
                overflowY: "scroll",
                overflowX: "hidden",
                position: "relative",
              }}
            >
                <div className="d-flex justify-content-betweens align-items-center">
                    <div className="w-100 rounded-pill ">
                      <select
                        id="lang"
                        className="w-100 custom-select"
                        onChange={(e)=> this.handleChangeCategory(e)}
                        value={this.state.selected_category}
                      >
                        <option>Select Category</option>
                        {this.state.bylaw_categories.map((category) => <option key={category}>{category}</option>)}
                      </select>
                    </div>
                </div>
                <hr />
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">
                      Restrictions
                    </a>
                  </li>
                </ul>
          
              <div className="tab-content" id="myTabContent">
                <div className="card p-4 tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  
                  {
                  this.state.is_sidebar_loading ?
                    <div >
                      <Spinner height={'20vh'} mainLoader={false}/>
                    </div>
                  :
                  <>
                  <div className="row pl-3 d-block">
                    <p className="font-weight-bold">
                      Summary
                    </p> 
                    <div>
                      
                      {this.state.client_bylaws.map(bylaw => {
                        if (bylaw.category === this.state.selected_category)
                        return <div key={bylaw.bylaw} className="fs-14">
                          <label htmlFor={bylaw.bylaw}>
                              {bylaw.bylaw}
                          </label>
                        </div>
                      })}
                    </div>
                  </div>
                  <Sidebar
                    highlights={selected_corpuses}
                    bylaw_authed = {false}
                    resetHighlights={this.resetHighlights}
                  />
                  </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ClientScreen;
