import React from 'react';
import './style/App.css'
import './style/checkmark.css'
import { Route} from 'react-router-dom';
import BylawScreen from './screens/BylawScreen';
import CallerScreen from './screens/CallerScreen';
import ClientScreen from './screens/ClientScreen';
import NotFoundScreen from './screens/NotFoundScreen';


const App = () => {
    return (
        <>
            <Route exact path = "/" component = {BylawScreen} />
            <Route exact path = "/caller" component = {CallerScreen} />
            <Route exact path = "/client" component = {ClientScreen} />
            <Route exact path = "/notfound" component = {NotFoundScreen} />
        </>
    )
}

export default App;
