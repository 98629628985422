import React from "react";

import "../style/Spinner.css";

export default (props) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: props.height ? props.height :"100vh"
      }}
    >   

      {props.mainLoader ? 
      <div className="m-auto">
      <div className="m-auto logo_loader"> 
          <img src="https://inspecthoa.s3.us-east-2.amazonaws.com/static/minilogo.svg" />
      </div>
      <p className="mt-2">
        {props.text ? props.text : 'This can take a few minutes as files can be large...'}
      </p>
      </div>
      : 
      <div className="sk-fading-circle">
        <div className="sk-circle1 sk-circle" />
        <div className="sk-circle2 sk-circle" />
        <div className="sk-circle3 sk-circle" />
        <div className="sk-circle4 sk-circle" />
        <div className="sk-circle5 sk-circle" />
        <div className="sk-circle6 sk-circle" />
        <div className="sk-circle7 sk-circle" />
        <div className="sk-circle8 sk-circle" />
        <div className="sk-circle9 sk-circle" />
        <div className="sk-circle10 sk-circle" />
        <div className="sk-circle11 sk-circle" />
        <div className="sk-circle12 sk-circle" />
      </div>
      
      }
      
    </div>
  );
};
