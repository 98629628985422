import { get_caller_note, edit_caller_note } from "../../api/services"


const initial_state = {
    note: null,
    updating_comment: false
}

const noteReducer = (state=initial_state, action) => {
    switch (action.type){

        case 'GET_NOTE':
            return {note: action.data.note}
        
        case 'UPDATE_NOTE':
            return {note: action.data.note}

        case 'GETTING_NOTE':
            return {...state, updating_comment: true}
        
        case 'GETTING_NOTE_FINISH':
            return {...state, updating_comment: false}

        default:
            return state
    }
}


export const get_note = (property_id) =>{
    return async dispatch => {
        try{
            dispatch({type:"GETTING_NOTE"})
            const data = await get_caller_note(property_id);
            dispatch({
                type: "GET_NOTE",
                data:{
                    note: data.note,
                }
            })
            dispatch({type:"GETTING_NOTE_FINISH"})
        }catch(err){
            console.error(err);
            dispatch({type:"GETTING_NOTE_FINISH"})
        }
    }
}

export const update_note = (property_id, note_content) =>{
    return async dispatch => {
        try{
            dispatch({type:"GETTING_NOTE"})
            const data = await edit_caller_note(property_id, note_content);
            dispatch({
                type: "UPDATE_NOTE",
                data:{
                    note: data.note,
                }
            })
            dispatch({type:"GETTING_NOTE_FINISH"})
        }catch(err){
            dispatch({type:"GETTING_NOTE_FINISH"})
            console.error(err);
        }
    }
}


export default noteReducer;
