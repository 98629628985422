import React from 'react'

const ArrowRight = (props) => {
    return (
        <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.33337 8H12.6667" stroke="#414141" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8 3.3335L12.6667 8.00016L8 12.6668" stroke="#414141" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default ArrowRight;