import React from "react";
import { useDispatch } from "react-redux";
import ArrowRight from "../assets/arrow-right";
import {edit_corpus} from "../redux/reducers/editcorpusReducer";


function CorpusItem({
    updateHash,
    bylaw_authed,
    highlight,
    togglecorpus,
    toggleirrelevance,
    index
}) {

    const dispatch = useDispatch();

    const handle_edit = (e) => {
        dispatch(edit_corpus({
            corpus_id: highlight.text_id,
            // corpus_text: highlight.content.text.slice(0, 450).trim()
            corpus_text: document.getElementById(`corpus-text-${highlight.text_id}`).innerHTML
        }))
    }

    return (
        <div
            key={index}
            className="corpus-item mt-4"
            onClick={() => {
                updateHash(highlight);
            }}
        > 
        <div className="d-flex ">
            {bylaw_authed && (
            <div className="mr-2">
                <div className="checkmark-container checkmark-container-success fs-14">
                    <label htmlFor={`corpus-${highlight.text_id}`}>
                        <input
                            type="checkbox"
                            key = {`corpus-${highlight.text_id}`}
                            id={`corpus-${highlight.text_id}`}
                            defaultChecked={highlight.selected===1}
                            value={highlight.selected===1 ? "on" : "off"}
                            onClick={(e) => {togglecorpus(e,highlight)}} 
                            name="check"
                        />
                        <span className="checkmark"></span>
                    </label>
                </div>
                <div className="checkmark-container checkmark-container-warning fs-14">
                    <label htmlFor={`irrelevant-${highlight.text_id}`}>
                        <input
                            type="checkbox"
                            key = {`irrelevant-${highlight.text_id}`}
                            id={`irrelevant-${highlight.text_id}`}
                            defaultChecked={highlight.selected===2}
                            value={highlight.selected===2 ? "on" : "off"}
                            onClick={(e) => {toggleirrelevance(e,highlight)}} 
                            name="check"
                        />
                        <span className="checkmark"></span>
                    </label>
                </div>
            </div>
            )}
            <div>
            <div style={{ cursor: "pointer" }}>
                {highlight.content.text ? (
                <blockquote className="fs-14" id={`corpus-text-${highlight.text_id}`}>
                    {`${highlight.content.text.slice(0, 450).trim()}`}
                </blockquote>
                ) : null}
                {highlight.content.image ? (
                <div
                    className="highlight__image"
                    style={{ marginTop: "0.5rem" }}
                >
                    <img src={highlight.content.image} alt={"Screenshot"} />
                </div>
                ) : null}
            </div>
            <div className="d-flex">            
                <button 
                    style={{paddingBottom:2}}
                    className="btn highlight__location font-weight-bold btn-outline-success btn-sm pt-0 pr-2 pl-2 highlight__location"
                >
                    {bylaw_authed ? <>Page {highlight.position.pageNumber}</> : <>Open</>} <ArrowRight style={{verticalAlign:'middle', padding:0, paddingBottom: 2.2, paddingLeft:0.5}}/>
                </button>
                {bylaw_authed && ( <button onClick={handle_edit} className="btn btn-info ml-2 btn-sm pt-0 pb-0 pr-2 pl-2" data-toggle="modal" data-target="#edit-corpus-modal">
                    Edit
                </button>)}
            </div>
            </div>
        </div>
        </div>
  );
}

export default CorpusItem;
