import {createStore, combineReducers, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import editcorpusReducer from './reducers/editcorpusReducer'
import noteReducer from './reducers/noteReducer'


const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['edit_corpus']
}

const rootReducer = combineReducers({
  edit_corpus: editcorpusReducer,
  caller_note: noteReducer
})


// Store below

const store = createStore(
  persistReducer(persistConfig, rootReducer),
  composeWithDevTools(applyMiddleware(thunk))
)

const persistor = persistStore(store)

export  {store, persistor}
