import React from 'react';
import Spinner from '../../components/Spinner';


const NotFoundScreen = () => {
    return (
        <div className=" d-flex justify-content-center">
            {/* Lost? Maybe wrong link? */}

            <Spinner text={"Maybe wrong link?"} mainLoader={true}/>
        </div>
    )
}

export default NotFoundScreen;
