// @flow

import React, { Component, useEffect } from "react";
import $ from "jquery";
import {
  PdfLoader,
  PdfHighlighter,
  Tip,
  Highlight,
  Popup,
  AreaHighlight,
} from "react-pdf-highlighter";
import Spinner from "../../components/Spinner";
import Sidebar from "../../components/Sidebar";
import { bylaw_auth, get_custom_bylaw, get_bylaw_answers, get_preset_bylaw, get_preset_bylaw_categories, get_property_corpus, get_property_corpus_summary, get_property_pdf, insert_new_corpus, save_selections_bylaw, BYLAW_BASE_URL } from "../../api/services";
import EditCorpusModal from "../../components/EditCorpusModal";
import { insertUrlParam } from "../../utilities/helpers";
import encrypt from "../../api/encrypt";
import InlineSpinner from "../../components/InlineSpinner";
import ZoomInButton from "../../assets/zoom-in-button.png"
import ZoomOutButton from "../../assets/zoom-out-button.png"

const getNextId = () => String(Math.random()).slice(2);

const parseIdFromHash = () =>
  document.location.hash.slice("#highlight-".length);

const resetHash = () => {
  document.location.hash = "";
};

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  return rect.y > -300 	
}

const HighlightPopup = ({ comment }) =>
  comment.text ? (
    <div className="Highlight__popup">
      {comment.emoji} {comment.text}
    </div>
  ) : null;

const initialUrl = "";


class BylawScreen extends Component {
  state = {
    property_id: null,
    bylaw_authed: false,
    url: initialUrl,
    highlights: [],
    categories: [],
    corpus_sentance: [],
    preset: [],
    selected_category: "select",
    drop: [],
    corpus_summary: "",
    keywords: [],
    current_page: 1,
    category_from_url: '',
    is_sidebar_loading: true,
    zoomed_in: false
  };


  componentDidMount(){
    const all =  new URLSearchParams(window.location.search);
    let property_id = null;
    let pid = all.get("pid");
    let word = null;

    try{
      if(pid){
        const decoded_string = encrypt.decryptWithAES(pid);
        const decoded_elements = decoded_string.split(',');
        property_id = decoded_elements[0];
        word = decoded_elements[1];
        if (word == "access"){
          this.setState({bylaw_authed: true})
        }
      }
    }catch(err){
      console.error(err);
    }
    
    if(word !== "access")  {
      this.props.history.push("/notfound")
    }

    const category = all.get("category");
    this.setState({category_from_url: category});

    if (property_id){
      this.setState({property_id: property_id});
 
      this.bylaw_categories_data(property_id);
      const show_pages = () => {
        try{
          const pages = document.querySelectorAll('.page');
          const pdfsection = document.querySelector('.PdfHighlighter');
          pdfsection.addEventListener('scroll',(e)=>{
          for (let i=0;i<pages.length;i++){
              let ii = pages[i];
              if (isInViewport(ii)){
              this.setState({current_page: ii.dataset.pageNumber})
              break;	
            }
            }
          })
        }catch(err){
          setTimeout(()=>{
            show_pages();
          }, 1000)
        }
      }
      show_pages();
    }
  }

  componentDidUpdate(oldState, newState){
    if (oldState.selected_category !== newState.selected_category){
      insertUrlParam('category', newState.selected_category)
    }
  }

  resetHighlights = () => {
    this.setState({
      highlights: [],
    });
  };

  scrollViewerTo = (highlight) => {};

  scrollToHighlightFromHash = () => {
    const highlight = this.getHighlightById(parseIdFromHash());
    if (highlight) {
      this.scrollViewerTo(highlight);
    }
  };

  async handleSubmit(event) {
    var next = $("#lang > option:selected").next("option");
    event.preventDefault();
    var d = { check: [] };
    $("input.selection:checked").each(function () {
      d["check"].push($(this).val());
    });

    if (this.state.bylaw_authed){
      try{
      this.setState({ saving_bylaw: true })
      await save_selections_bylaw(d, this.state.selected_category, this.state.property_id);
      }catch(err){
        console.error(err);
      }finally{
      await this.bylaw_categories_data(this.state.property_id, false)
      this.setState({ saving_bylaw: false })
      if (next.length > 0) {
        $("#lang > option:selected")
          .removeAttr("selected")
          .next("option")
          .attr("selected", "selected");
        this.change_category(next.val());
        this.setState({ selected_category: next.val() });
      } else {
        this.change_category(this.state.categories[0].key);
        this.setState({ selected_category: this.state.categories[0].key });
      }
      }
    }
    
  }

  async listCustomBylaw(property_id, category){
    try{
      const get_preset_bylaw_data = await get_custom_bylaw(property_id, category);
      const drops = get_preset_bylaw_data.map((bylaw) => {
        return (
          <div key={bylaw.id} className="fs-14">
            <label htmlFor={bylaw.id}>
                {bylaw.custom_bylaw}
            </label>
          </div>
        )
      });
      this.setState({ drop: [...this.state.drop, ...drops] });
    }catch(err){
      console.error(err);
    }
  }
 
  async listOptions(category) {
    try{
      const get_preset_bylaw_data = await get_preset_bylaw(this.state.property_id, category);
      let keywords = [];
      get_preset_bylaw_data.forEach(element => {
        const splitted_keywords = element.keywords.split(",");
        keywords = [...keywords, ...splitted_keywords];
        return element.keywords;
      });
      this.setState({ keywords: keywords.filter(onlyUnique) });
      const drops = get_preset_bylaw_data.map((planet) => {
        if (this.state.bylaw_authed)
          return (
            <div key={planet.id} className="checkmark-container checkmark-container-success fs-14">
              <label htmlFor={planet.id}>
                  {planet.bylaw}
                  <input
                    type="checkbox"
                    key = {planet.id}
                    id={planet.id}
                    className="selection"
                    value={planet.id}
                    name="check"
                    defaultChecked={this.state.preset.includes(planet.id)}
                  />
                  <span className="checkmark"></span>
              </label>
            </div>
          )
        if(!this.state.bylaw_authed && this.state.preset.includes(planet.id)) {
          return (
            <div key={planet.id} className="fs-14">
              <label htmlFor={planet.id}>
                  {planet.bylaw}
              </label>
            </div>
          )
        }
        return undefined
      });
      let sz = drops.filter(d => d!==undefined);
      this.setState({ drop: sz});
      try{
        if (!this.state.bylaw_authed){
          await this.listCustomBylaw(this.state.property_id, category);
        }
      }catch(err){
        console.error(err);
      }
      

    }catch(err){console.error(err);}
  }

  async change_category(new_category) {
    resetHash();
    this.setState({is_sidebar_loading: true})
    try{
      const get_property_pdf_data = await get_property_pdf(this.state.property_id);
      this.setState({ url: get_property_pdf_data });
    }catch(err){console.error(err);}

    try{
      const get_preset_data = await get_bylaw_answers(this.state.property_id);
      const preset_array = get_preset_data.map((planet) => planet.bylaw_id)
      this.setState({ preset: preset_array });
    }catch(err){console.error(err);}

    await this.listOptions(new_category);

    try{
      const get_property_corpus_summary_data = await get_property_corpus_summary(new_category, this.state.property_id);
      const corpus_sum = get_property_corpus_summary_data.map((planet) => {
        return <p key={planet.id}>{planet.corpus_summary}</p>;
      });
      this.setState({ corpus_summary: corpus_sum });
    }catch(err){console.error(err);}

    try{
      const get_property_corpus_data = await get_property_corpus(new_category, this.state.property_id);
      const corpus_highlight = get_property_corpus_data.map((planet) => planet); 
      const aa = [];
      corpus_highlight.map((p) => {
        var item = JSON.parse(p.location)
        aa.push({
          content: item.content,
          position: {
            boundingRect: {
              x1: item.position.boundingRect.x1,
              y1: item.position.boundingRect.y1,
              x2: item.position.boundingRect.x2,
              y2: item.position.boundingRect.y2,
              width: item.position.boundingRect.width,
              height: item.position.boundingRect.height
            },
            rects: [
              {
                x1: item.position.rects[0].x1,
                y1: item.position.rects[0].y1,
                x2: item.position.rects[0].x2,
                y2: item.position.rects[0].y2,
                width: item.position.rects[0].width,
                height: item.position.rects[0].height
              }
            ],
            pageNumber:  item.position.pageNumber
          },
          comment: item.comment,
          id: item.id.toString(),
          text_id: p.id,
          selected: p.selected,
          category: p.category,
          property_id : this.state.property_id,
        });
        
      });
      if (this.state.bylaw_authed){
        this.setState({ highlights: aa });
      }else{
        this.setState({ highlights: aa.filter(a => a.selected) });
      }
      this.setState({is_sidebar_loading: false})
    }catch(err){
      console.log(err);
    }

    window.addEventListener(
      "hashchange",
      this.scrollToHighlightFromHash,
      false
    );
  }

  handleChange(event) {
    const selected_new_category = event.target.value;
    this.setState({ selected_category: selected_new_category});
    this.change_category(event.target.value);
  }

  bylaw_categories_data = async (property_id, set_current_category_flag = true) => {
    try{
      const get_preset_bylaw_categories_data = await get_preset_bylaw_categories(property_id);
      const categories = get_preset_bylaw_categories_data.map((planet) => {
        return <option key={planet.category}>{planet.category}</option>;
      });
      if(categories.length!==0){
        this.setState({ categories: categories });
        if (set_current_category_flag){
          if (this.state.category_from_url){
            this.setState({ selected_category: this.state.category_from_url });
            this.change_category(this.state.category_from_url);
          }
          else{
            this.setState({ selected_category: this.state.categories[0]?.key });
            this.change_category(this.state.categories[0].key);
          }
        }
      return true;
      }
    }catch(err){
      console.log(err);
      return false;
    }
  }

  getHighlightById(id) {
    const { highlights } = this.state;
    return highlights.find((highlight) => {
      const ok = highlight.id === id;
      return ok;
    });
  }

  async addHighlight(highlight) {
      const next_id = getNextId();
      const new_highlight = {
        ...highlight,
        id: next_id
      }
      highlight.id =  getNextId()
      try{
        if (this.state.bylaw_authed){
          await insert_new_corpus(this.state.property_id, new_highlight, this.state.selected_category);
          this.change_category(this.state.selected_category);
        }
      }catch(err){
        console.log(err);
      }
  }

  updateHighlight(highlightId, position, content) {
    this.setState({
      highlights: this.state.highlights.map((h) => {
        return h.id === highlightId
          ? {
              ...h,
              position: { ...h.position, ...position },
              content: { ...h.content, ...content },
            }
          : h;
      }),
    });
  }

  render() {
    const { url, highlights } = this.state;
    return (
      <div className="Pdf">
        <div className="container-fluid">
          <div className="row p-4">
            <div
              className="col-md-8"
              style={{
                height: "92vh",
                width: "70vh",
                overflowY: "scroll",
                overflowX: "hidden",
                position: "relative",
              }}
            >
              <div className="card m-3 p-3 bg-secondary  d-flex flex-row justify-content-between">
                <div>Property ID: {this.state.property_id}</div>
                <div> 
              
                  <span 
                    style={{cursor: "pointer"}}
                    onClick={() => {
                      if(this.state.zoomed_in){
                        window.PdfViewer.viewer.currentScaleValue = 1
                        this.setState({zoomed_in: false})
                      }else{
                        window.PdfViewer.viewer.currentScaleValue = 2
                        this.setState({zoomed_in: true})
                      }
                    }}
                  >
                    {this.state.zoomed_in ? <img width={20} src={ZoomOutButton} /> : <img width={20}  src={ZoomInButton} />}
                  </span>
                  
                </div>
                <div>{this.state.current_page} / {this.total_pages}</div>
              </div>
              <PdfLoader url={url} beforeLoad={<Spinner mainLoader={true} />} >
                {(pdfDocument) => 
                {
                  this.total_pages = pdfDocument._pdfInfo.numPages;
                  return (
                  <PdfHighlighter
                    pdfDocument={pdfDocument}
                    enableAreaSelection={(event) => event.shift}
                    onScrollChange={()=>{
                      resetHash();
                    }}
                    scrollRef={(scrollTo) => {
                      this.scrollViewerTo = scrollTo;
                      this.scrollToHighlightFromHash();
                    }}
                    onSelectionFinished={(
                      position,
                      content,
                      hideTipAndSelection,
                      transformSelection
                    ) => (
                      <Tip
                        onOpen={transformSelection }
                        onConfirm={(comment) => {
                          this.addHighlight({ content, position, comment });
                          hideTipAndSelection();
                        }}
                      />
                    )}
                    highlightTransform={(
                      highlight,
                      index,
                      setTip,
                      hideTip,
                      viewportToScaled,
                      screenshot,
                      isScrolledTo
                    ) => {
                      const isTextHighlight = !Boolean(
                        highlight.content && highlight.content.image
                      );
                      const component = isTextHighlight ? (
                        <Highlight
                          isScrolledTo={isScrolledTo}
                          position={highlight.position}
                          comment={highlight.comment}
                        />
                      ) : (
                        <AreaHighlight
                          highlight={highlight}
                          onChange={(boundingRect) => {
                            this.updateHighlight(
                              highlight.id,
                              {
                                boundingRect: viewportToScaled(boundingRect),
                              },
                              { image: screenshot(boundingRect) }
                            );
                          }}
                        />
                      );
                      return (
                        <Popup
                          popupContent={<HighlightPopup {...highlight} />}
                          onMouseOver={(popupContent) =>
                            setTip(highlight, (highlight) => popupContent)
                          }
                          onMouseOut={hideTip}
                          key={index}
                          children={component}
                        />
                      );
                    }}
                    highlights={highlights}
                  />
                )}}
              </PdfLoader>
            </div>
            <div
              className="col-md-4 sidebar-menu"
              style={{
                height: "97vh",
                width: "100vh",
                overflowY: "scroll",
                overflowX: "hidden",
                position: "relative",
              }}
            >
                <form className="d-flex justify-content-betweens align-items-center" onSubmit={this.handleSubmit.bind(this)}>
                    <div className="w-100 rounded-pill ">
                      <select
                        id="lang"
                        className="w-100 custom-select"
                        onChange={this.handleChange.bind(this)}
                        value={this.state.selected_category}
                      >
                        <option>Select</option>
                        {this.state.categories}
                      </select>
                    </div>
                    <div className="col-md-4 float-left h-100">
                      {this.state.saving_bylaw ?
                        <InlineSpinner/>
                      :
                      <input
                        type="submit"
                        className="form-control btn btn-warning text-white shadow font-weight-bold"
                        value={this.state.bylaw_authed ? "Save & Next" : "Next"} 
                      />
                    }
                    </div>
                </form>
                <hr />

                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">
                      
                      {this.state.bylaw_authed ? "Options/Corpus" : "Restrictions"} 
                    </a>
                  </li>
                  {
                    this.state.bylaw_authed && 
                    <li className="nav-item">
                      <a className="nav-link" id="contact-tab" data-toggle="tab" href="#keywords" role="tab" aria-controls="keywords" aria-selected="false">Keywords</a>
                    </li>
                  }
                  
                </ul>

              {
              this.state.is_sidebar_loading ?
                <div >
                  <Spinner height={'20vh'}/>
                </div>

              :
              <div className="tab-content" id="myTabContent">
                <div className="card p-4 tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <div className="row pl-3 d-block">
                    <p className="font-weight-bold">
                     { this.state.drop.length>0 && ( <>{this.state.bylaw_authed ? "Please select from the options below:" : "Summary"}</>) }
                    </p> 
                    <div>
                      {this.state.drop}
                      <input
                        type="hidden"
                        value={this.state.selected_category}
                        id="categroy"
                      />
                    </div>
                  </div>
                  {this.state.bylaw_authed &&  

                  <div className="row bg-secondary">
                    <div className="col text-left p-3">
                      <p className="text-black font-weight-bold ">Corpus Summary</p>
                      <div className="fs-12">{this.state.corpus_summary}</div>  
                    </div>
                  </div>
                  }
                  <Sidebar
                    highlights={highlights}
                    bylaw_authed = {this.state.bylaw_authed}
                    resetHighlights={this.resetHighlights}
                  />
                </div>
                {BYLAW_BASE_URL === 'https://test-myapi.inspecthoa.com/api/v1.1/bylaws' && <a href={`https://staging.d1eq2evwlplvh6.amplifyapp.com/client?pid=${encodeURIComponent(encrypt.encryptWithAES(this.state.property_id?.toString()))}`} target="_none" className="btn btn-warning mt-3 text-white">Client View</a>}
                {BYLAW_BASE_URL === 'https://myapi.inspecthoa.com/api/v1.1/bylaws' && <a href={`https://summary.inspecthoa.com/client?pid=${encodeURIComponent(encrypt.encryptWithAES(this.state.property_id?.toString()))}`} target="_none" className="btn btn-warning mt-3 text-white">Client View</a>}

                <div className="tab-pane fade mt-2 fs-12" id="keywords" role="tabpanel" aria-labelledby="keywords-tab" >
                  {  this.state.keywords.map((keyword, index) => {
                    return <span key={index} className="badge badge-info  p-2 m-2 fs-12">{keyword}</span>
                  }) }
                </div>
              </div>
              }

            </div>
          </div>
        </div>
        <EditCorpusModal/>
      </div>
    );
  }
}

export default BylawScreen;
