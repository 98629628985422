/* eslint-disable no-undef */

import React, { Component } from "react";
import Spinner from "../../components/Spinner";
import {
  get_custom_bylaw,
  get_caller_answers,
  get_preset_bylaw,
  get_preset_bylaw_categories,
  get_property_pdf,
  save_selections_caller,
} from "../../api/services";
import { insertUrlParam } from "../../utilities/helpers";
import { connect } from "react-redux";
import {update_note, get_note} from '../../redux/reducers/noteReducer'
import moment from "moment";
import encrypt from "../../api/encrypt";

const initialUrl = "";


class CallerScreen extends Component {
  constructor(props){
    super(props);
    this.handleSaveComment = this.handleSaveComment.bind(this);
  }
  state = {
    property_id: null,
    bylaw_authed: false,
    url: initialUrl,
    categories: [],
    preset: [],
    selected_category: "select",
    drop: [],
    category_from_url: "",
    is_sidebar_loading: true,
  };

  componentDidMount() {
    const all = new URLSearchParams(window.location.search);
    let property_id = all.get("property_id");
    const category = all.get("category");
    this.setState({ category_from_url: category });

    let pid = all.get("pid");

    if (pid) {
      const decoded_string = encrypt.decryptWithAES(pid);
      const decoded_elements = decoded_string.split(',');
      property_id = decoded_elements[0];
      this.setState({ property_id: property_id });
      this.bylaw_categories_data(property_id);
    }
    this.props.get_note(property_id);
    
    const save_note =() =>{
      this.handleSaveComment();
    }
    $('#summernoteCaller').summernote({
      height: 600,
      toolbar: [ 
        ["history", ["undo", "redo"]], 
        ["font", ["bold", "underline"]], 
        ["color", ["backcolor"]],
      ],
      callbacks: {
        onBlur: function() {
          save_note()
        }
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selected_category !== this.state.selected_category) {
      insertUrlParam("category", this.state.selected_category);
    }
    if (prevState.categories?.length == 0 && this.state.categories?.length > 0){
      this.prepare_text_editor()
    }
  }


  async handleSaveBylaws(event) {
    this.setState({is_sidebar_loading: true});
    var next = $("#lang > option:selected").next("option");
    event.preventDefault();
    var selections = { check: [] };
    $("input.selection:checked").each(function () {
      selections["check"].push($(this).val());
    });

    try {
      await save_selections_caller(
        selections,
        this.state.selected_category,
        this.state.property_id
      );
    } catch (err) {
      console.error(err);
    }
    this.setState({is_sidebar_loading: false});
    if (next.length > 0) {
      $("#lang > option:selected")
        .removeAttr("selected")
        .next("option")
        .attr("selected", "selected");
      this.change_category(next.val());
      this.setState({ selected_category: next.val() });
    } else {
      this.change_category(this.state.categories[0].key);
      this.setState({ selected_category: this.state.categories[0].key });
    }
  }

  async listCustomBylaw(property_id, category) {
    try {
      const get_preset_bylaw_data = await get_custom_bylaw(
        property_id,
        category
      );
      const drops = get_preset_bylaw_data.map((planet) => {
        return (
          <div key={planet.id} className="fs-14">
            <label htmlFor={planet.id}>{planet.custom_bylaw}</label>
          </div>
        );
      });
      this.setState({ drop: [...this.state.drop, ...drops] });
    } catch (err) {
      console.error(err);
    }
  }

  async listOptions(category) {
    try {
      const get_preset_bylaw_data = await get_preset_bylaw(this.state.property_id, category);
      const drops = get_preset_bylaw_data.map((preset_bylaw_option) => {
          return (
            <div
              key={preset_bylaw_option.id}
              className="checkmark-container checkmark-container-success fs-14"
            >
              <label htmlFor={preset_bylaw_option.id}>
                {preset_bylaw_option.bylaw}
                <input
                  type="checkbox"
                  key={preset_bylaw_option.id}
                  id={preset_bylaw_option.id}
                  className="selection"
                  value={preset_bylaw_option.id}
                  name="check"
                  defaultChecked={this.state.caller_answers.includes(preset_bylaw_option.id)}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          );
      });
      let sz = drops.filter((d) => d !== undefined);
      this.setState({ drop: sz });
    } catch (err) {
      console.error(err);
    }
  }

  async change_category(v) {
    this.setState({ is_sidebar_loading: true });
    try {
      const get_property_pdf_data = await get_property_pdf(
        this.state.property_id
      );
      this.setState({ url: get_property_pdf_data });
    } catch (err) {
      console.error(err);
    }

    try {
      const get_preset_data = await get_caller_answers(this.state.property_id);
      const caller_answers_array = get_preset_data.map((caller_answer) => caller_answer.bylaw_id);
      this.setState({ caller_answers: caller_answers_array });
    } catch (err) {
      console.error(err);
    }

    await this.listOptions(v);

    this.setState({ is_sidebar_loading: false });
  }

  prepare_text_editor(){
    const note_text = this.props.caller_note?.note?.text;
    let template = ""
    if(note_text){
      template = note_text;
    }
    else{
      const categories = this.state.categories;
      categories.forEach(c => {
        template += `<p>  <span style="font-weight: 700">${c.key}</span> <p style="font-size:13px; text-color: gray;">Note: </p> </p>`
      })
    }
    $('#summernoteCaller').summernote('pasteHTML', template)
  }

  handleChange(event) {
    this.setState({ selected_category: event.target.value });
    this.change_category(event.target.value);
  }

  bylaw_categories_data = async (property_id) => {
    try {
      const get_preset_bylaw_categories_data =
        await get_preset_bylaw_categories(property_id);
      const categories = get_preset_bylaw_categories_data.map((planet) => {
        return <option key={planet.category}>{planet.category}</option>;
      });
      if (categories.length !== 0) {
        this.setState({ categories: categories });
        if (this.state.category_from_url) {
          this.setState({ selected_category: this.state.category_from_url });
          this.change_category(this.state.category_from_url);
        } else {
          this.setState({ selected_category: this.state.categories[0]?.key });
          this.change_category(this.state.categories[0].key);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  async handleSaveComment(e= null){
    if (e)e.preventDefault();
    const comment = document.getElementById('summernoteCaller').value;
    await this.props.update_note(this.state.property_id, comment);
  }

  render() {
    const note_state = this.props.caller_note;
    return (
      <div className="d-flex p-4" style={{height:'100vh'}}>
        <div className="w-100 p-4">
          <p className="text-muted mb-0">
            <b>Property ID:</b> {this.state.property_id}
          </p>
          <p className="text-muted mt-0 font-weight-bold">
            Caller Notes {" - "}

            {note_state.updating_comment ? <small>Saving...</small>  :
            note_state?.note?.updated_at 
              ? <small>Last edit was {moment(note_state?.note?.updated_at).startOf('minutes').fromNow()}</small>
              : <small>New note</small>
            }

          </p>
          <div className="bg-white">
            <textarea id="summernoteCaller"/>
          </div>
          <div>
            <button 
              onClick={(e)=>this.handleSaveComment(e)} 
              className="btn btn-warning mt-2 text-white"
              disabled={note_state.updating_comment}
            >
            {note_state.updating_comment ? "Saving..." : "Save notes"}
            </button>
          </div>
        </div>
        <div
          className="w-25 p-2"
          style={{
            overflowY: "scroll",
            overflowX: "hidden",
            position: "relative",
          }}
        >
          <form
            className="d-flex justify-content-betweens align-items-center"
            onSubmit={this.handleSaveBylaws.bind(this)}
          >
            <div className="w-100 rounded-pill ">
              <select
                id="lang"
                className="w-100 custom-select"
                onChange={this.handleChange.bind(this)}
                value={this.state.selected_category}
              >
                <option>Select</option>
                {this.state.categories}
              </select>
            </div>
            <div className="w-50 p-2 float-left">
              <input
                type="submit"
                className="form-control btn btn-warning text-white shadow  font-weight-bold"
                value={"Save & Next"}
              />
            </div>
          </form>
          <hr />

          {this.state.is_sidebar_loading ? (
            <div className="tab-content" id="myTabContent">
              <div
                className="card p-4 tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <Spinner height={'20vh'} mainLoader={false} />
              </div>
            </div>
          ) : (
            <div
              className="card p-4 tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div className="d-block">
                <p className="font-weight-bold">
                  Please select from the options below:
                </p>
                <div>
                  {this.state.drop}
                  <input
                    type="hidden"
                    value={this.state.selected_category}
                    id="categroy"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}


const mapStateToProps = ({ caller_note }) => {
  return { caller_note };
};

const mapActionsToProps = {
  get_note,
  update_note
};

export default connect(mapStateToProps, mapActionsToProps)(CallerScreen);
