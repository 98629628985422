const CryptoJS = require('crypto-js');
const passphrase = 'safeopozeeeeeeee';

const encryptWithAES = (text) => {
  const key = CryptoJS.enc.Utf8.parse(passphrase); 
  
  return CryptoJS.AES.encrypt(text, key, {mode:CryptoJS.mode.ECB}).toString();
};

const decryptWithAES = (ciphertext) => {
  const key = CryptoJS.enc.Utf8.parse(passphrase); 
  const bytes = CryptoJS.AES.decrypt(ciphertext, key ,{mode:CryptoJS.mode.ECB})
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

export default {
    encryptWithAES,
    decryptWithAES
}
