

const initial_state = {
    edit_id: null,
    edit_text: null
}

const editcorpusReducer = (state=initial_state, action) => {
    switch (action.type){

        case 'CHANGE_CORPUS':
            return action.data
            
        default:
            return state
    }
}


export const edit_corpus = (data) =>{
    return async dispatch => {
        try{
            dispatch({
                type: "CHANGE_CORPUS",
                data
            })
        }catch(err){
            console.log("Err");
        }
    }
}


export default editcorpusReducer
