export const insertUrlParam = (key, value) => {
    try {
        if (window.history.pushState) {
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set(key, value);
        let newurl =
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname +
            "?" +
            searchParams.toString() +
            window.location.hash;
        window.history.pushState({ path: newurl }, "", newurl);
        }
    } catch (err) {
        console.error(err);
    }
};