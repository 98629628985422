import React, { useContext, useEffect, useReducer, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { edit_property_corpus } from "../api/services";
import { edit_corpus } from "../redux/reducers/editcorpusReducer";

const EditCorpusModal = () => {
    const corpus_values = useSelector( ({edit_corpus}) => edit_corpus)
    const dispatch = useDispatch();

    const handle_change = async (e) => {
        dispatch(edit_corpus({
            ...corpus_values,
            corpus_text: e.target.value
        }))
    }

    const handle_submit = async (e) => {
        e.preventDefault();
        try{
            await edit_property_corpus(corpus_values.corpus_id, corpus_values.corpus_text);
            document.getElementById(`corpus-text-${corpus_values.corpus_id}`).innerHTML = corpus_values.corpus_text;
            dispatch(edit_corpus({
                ...corpus_values,
            }))
        }catch(err){
            console.log(err);
        }
    }

    return (
        <div className="modal" id="edit-corpus-modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">Edit Corpus</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <textarea className="form-control" value={corpus_values.corpus_text || ""} onChange={handle_change}>
                </textarea>
            </div>
            <div className="modal-footer">
                <button onClick={handle_submit} type="button" className="btn btn-warning text-white" data-dismiss="modal">Save changes</button>
                <button type="button" className="btn" data-dismiss="modal">Close</button>
            </div>
            </div>
        </div>
        </div>
    )
}

export default EditCorpusModal;
